import axios from "axios";

const API = () => {
  return new Promise((resolve) => {
    const api = axios.create({
      timeout: process.env.REACT_APP_TIMEOUT,
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Web-Secret": `Bearer ${process.env.REACT_APP_WEB_SECRET}`,
      },
    });

    api.interceptors.response.use(
      (response) => {
        response.request = {};
        return response;
      },
      (error) => {
        if (error.message === "Network Error") {
          error.response = {
            data: {
              message: "Check your internet connectivity and try again.",
            },
          };

          alert("Check your internet connectivity and try again.");

          throw error;
        }

        if (!error.response) {
          throw error;
        }
      }
    );

    resolve(api);
  });
};

export { API };
