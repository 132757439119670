import React from "react";
import faq from "../../data/faq.json";

class Faq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section ptb-100">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-9">
                <div className="section-heading mb-5">
                  <h2>{faq.title}</h2>
                  <p
                    className="lead"
                    dangerouslySetInnerHTML={{
                      __html: faq.description,
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="row col-row">
              <div className="col-lg-6 col-row-lg">
                <div id="accordion-1" className="accordion accordion-faq">
                  {faq.data
                    .sort((a, b) => a.order - b.order)
                    .map((item, index) => {
                      return (
                        <div key={index} className="card">
                          <div
                            className="card-header py-4"
                            id={`heading-1-${index + 1}`}
                            data-toggle="collapse"
                            role="button"
                            data-target={`#collapse-1-${index + 1}`}
                            aria-expanded="false"
                            aria-controls={`collapse-1-${index + 1}`}
                          >
                            <h6 className="mb-0">
                              <span className="ti-receipt mr-3"></span>{" "}
                              {item.title}
                            </h6>
                          </div>
                          <div
                            id={`collapse-1-${index + 1}`}
                            className="collapse"
                            aria-labelledby={`heading-1-${index + 1}`}
                            data-parent={`#accordion-1`}
                          >
                            <div className="card-body">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.info,
                                }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="gray-light-bg ptb-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading mb-5 text-center">
                  <h2>Frequently Asked Questions</h2>
                  <p className="lead">
                    Quickly morph client-centric results through performance
                    based applications. Proactively facilitate professional
                    human capital for cutting-edge.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>How can I pay for this?</h5>
                  <p>
                    Intrinsicly implement high standards in strategic theme
                    areas via inexpensive solutions. Assertively conceptualize
                    prospective bandwidth whereas client-based imperatives.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Is it possible to pay yearly?</h5>
                  <p>
                    Assertively iterate user friendly innovation without
                    open-source markets. Monotonectally extend resource sucking
                    manufactured products without high-payoff paradigms.
                    Objectively customize ubiquitous information before
                    economically sound relationships.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Do you offer discounts on multiple items?</h5>
                  <p>
                    Dramatically target focused testing procedures after
                    holistic ideas. Collaboratively maximize high-payoff ROI and
                    value-added products. Distinctively deliver cooperative
                    collaboration and idea-sharing whereas customized
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Is VAT included in plan prices?</h5>
                  <p>
                    Distinctively simplify high-quality initiatives for highly
                    efficient applications. Monotonectally repurpose integrated
                    customer service after magnetic e-services.{" "}
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Will I pay more for some features?</h5>
                  <p>
                    Enthusiastically pontificate resource-leveling supply chains
                    whereas scalable markets. Authoritatively streamline
                    resource maximizing methods of empowerment
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="single-faq">
                  <h5>Why are there no limits on the number of messages?</h5>
                  <p>
                    Assertively target turnkey ideas for market-driven portals.
                    Appropriately e-enable world-class intellectual capital
                    whereas 2.0 mindshare.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </React.Fragment>
    );
  }
}

export default Faq;
