import React from "react";

import terms from "../../data/terms.json";

export default function Terms() {
  return (
    <React.Fragment>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-8 col-md-9"
              style={{ flex: 1, maxWidth: "100%" }}
            >
              <div className="section-heading mb-5">
                <h2>{terms.title}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: terms.description,
                  }}
                ></p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6" style={{ flex: 1, maxWidth: "100%" }}>
              <div id="accordion-1" className="accordion accordion-faq">
                {terms.data.map((item, index) => {
                  return (
                    <div className="card" key={index}>
                      <div
                        className="card-header py-4"
                        id={`heading-1-${index + 1}`}
                        data-toggle="collapse"
                        role="button"
                        data-target={`#collapse-1-${index + 1}`}
                        aria-expanded="false"
                        aria-controls={`collapse-1-${index + 1}`}
                      >
                        <h6 className="mb-0">
                          <span className="ti-receipt mr-3"></span>
                          {item.title}
                        </h6>
                      </div>
                      <div
                        id={`collapse-1-${index + 1}`}
                        className="collapse"
                        aria-labelledby={`heading-1-${index + 1}`}
                        data-parent="#accordion-1"
                      >
                        <div className="card-body">
                          <ol
                            style={{
                              listStyleType: "decimal",
                              listStylePosition: "inside",
                            }}
                          >
                            {item.listItems.map((listItem, index) => {
                              return (
                                <li
                                  key={index}
                                  dangerouslySetInnerHTML={{
                                    __html: listItem,
                                  }}
                                ></li>
                              );
                            })}
                          </ol>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
