import React from "react";

import privacy from "../../data/privacy.json";

export default function Privacy() {
  return (
    <React.Fragment>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-8 col-md-9"
              style={{ flex: 1, maxWidth: "100%" }}
            >
              <div className="section-heading mb-5">
                <h2>{privacy.title}</h2>
                <p>{privacy.description}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6" style={{ flex: 1, maxWidth: "100%" }}>
              <div id="accordion-1" className="accordion accordion-faq">
                {privacy.data
                  .sort((a, b) => a.order - b.order)
                  .map((item, index) => {
                    return (
                      <div className="card" key={index}>
                        <div
                          className="card-header py-4"
                          id={`heading-1-${index + 1}`}
                          data-toggle="collapse"
                          role="button"
                          data-target={`#collapse-1-${index + 1}`}
                          aria-expanded="false"
                          aria-controls={`collapse-1-${index + 1}`}
                        >
                          <h6 className="mb-0">
                            <span className="ti-receipt mr-3"></span>
                            {item.title}
                          </h6>
                        </div>
                        <div
                          id={`collapse-1-${index + 1}`}
                          className="collapse"
                          aria-labelledby={`heading-1-${index + 1}`}
                          data-parent="#accordion-1"
                        >
                          <div className="card-body">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.info,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
