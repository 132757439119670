module.exports = {
  header: {
    login: "Login",
    items: [
      {
        title: "Home",
        link: "#home",
      },
      {
        title: "About",
        link: "#about",
      },
      {
        title: "Features",
        link: "#features",
      },
      {
        title: "Screenshots",
        link: "#screenshots",
      },
      {
        title: "Team",
        link: "#team",
      },
      {
        title: "Contact",
        link: "#contact",
      },
    ],
  },
  signin: {
    title: "Welcome Back",
    subtitle:
      "Keep your face always toward the sunshine - and shadows will fall behind you",
    formTitle: "Login",
    formSubtitle: "Sign in to your account to continue",
    email: "Email Address",
    password: "Password",
    forgotPassword: "Forgot password?",
    register: {
      not: "Not registered?",
      create: "Create account",
    },
  },
  signup: {
    title: "Create Your Account",
    subtitle:
      "Keep your face always toward the sunshine - and shadows will fall behind you",
    formTitle: "Create account",
    formSubtitle: "Made with love by Hoojoozat",
    name: "Your Name",
    restaurant: "Restaurant Name",
    email: "Email Address",
    password: "Password",
    account: {
      already: "Already have an account?",
      signin: "Sign in",
    },
    terms: {
      agree: "I agree to the",
      link: "terms and conditions",
    },
  },
  hero: {
    title: "BOOKING",
    subtitle: ", Made Easy",
    description:
      "The most advanced reservation software and seating management platform. Make your business reservations experience clickable.",
    watchVideo: "Watch video overview",
    bgImage: "img/app-hero-bg.jpg",
    getQuoteTitle: "Get a quote today",
    getQuoteDescription: "Get response within 24 hours",
    agreeToTerms: "I agree to your",
    termsAndConditions: "terms & conditions",
    video: {
      link: "https://www.youtube.com/watch?v=r53zTsWq7Ho",
    },
  },
  promo: {
    title: "",
    description: "",
    items: [
      {
        title: "Creative Design",
        description:
          "Great design tells a story. Communication is a vital part of any business, and it's true that a picture speaks a thousand words. Good creative design will help you create a strong impression, build a brand identity and consumer trust.",
        icon: "ti-vector text-white",
      },
      {
        title: "Data Protection",
        description:
          "The Data Protection Act protects the privacy and integrity of data held on individuals by businesses and other organisations. This ensures customers have access to their data and can manage it.",
        icon: "ti-lock text-white",
      },
      {
        title: "Free Support",
        description:
          "Customer service is important, it can help you to: Increase customer loyalty. Increase the amount of money each customer spends with your business. Increase how often a customer buys from you.",
        icon: "ti-eye text-white",
      },
    ],
  },
  aboutUs: {
    subtitle: "About our app",
    title: "Easy Platform? Hoojoozat!",
    description:
      "Hoojoozat is a user-friendly app, easy to navigate as well as visually appealing. No need to spend a lot of time learning how to use our app as it embraces simplicity.",
    items: [
      {
        title: "Quick Online Reservation",
        description:
          "With our quick and easy online reservation platform, you can manage your reservations, accept bookings online and better manage your phone and in-person bookings.",
        icon: "ti-vector text-white",
      },
      {
        title: "Floor & Table Management",
        description:
          "We will help your team run faster and better. The all-in-one functional but multi dashboards platform enables your staff to enjoy a seamless user experience while focusing on servicing their guests, up-selling with menu items, and personalizing the dining experience.",
        icon: "ti-lock text-white",
      },
      {
        title: "Data Profiling & Reports",
        description:
          "Because what is more powerful than knowing your guests? Generate automatically and send reports to track real-time performance across several venues. Access numerous insightful reports segmented by outlet or group or customize the report that suits your operational needs to better understand how to service guests.",
        icon: "ti-eye text-white",
      },
      {
        title: "SMS Campaign Management",
        description:
          "You don't have to be an expert to a campaign! You can easily create and schedule targeted SMS and/or Email campaigns directly from Hoojoozat platform to ignite guest footfall for any promotional event.",
        icon: "ti-vector text-white",
      },
      {
        title: "Manage your Waiting List",
        description:
          "Our dynamic waiting list management tool allows you to prioritize reservations and walk-ins based upon capacity and profiles of guests in order to maximize revenue. Moreover, communicating with guests in real-time optimizes inventory and manages guest wait-time expectations!",
        icon: "ti-lock text-white",
      },
      {
        title: "View your Comment Cards",
        description:
          "Collecting customer feedback shows you value their opinions. By asking your clients for feedback you communicate that their opinion is important to you, you involve them in shaping your business so they feel more attached to your company. Listening to their voice helps you create stronger relations with them.",
        icon: "ti-eye text-white",
      },
    ],
  },
  features: {
    items: [
      {
        title: "Valet Parking Service",
        description:
          "In contrast to 'self-parking', Hoojoozat will help customers to find a parking space. Customers' vehicles will be parked for them by a professional valet, and delivered to them with a single click!",
        icon: "ti-face-smile",
      },
      {
        title: "Digital eMenu",
        description:
          "Upload your menu, generate and customize your QR code, create your Digital eMenu and share it with you customers to build a better user experience.",
        icon: "ti-vector",
      },
      {
        title: "Friendly Quick Support",
        description:
          "Get support for your business, chat with our support team at anytime from anywhere in an easy way, for a better and fast troubleshooting!",
        icon: "ti-headphone-alt",
      },
    ],
  },
  screenshots: {
    title: "App screenshots",
    subtitle: "Looks awesome",
    description:
      "Here are some awesome screenshots about Hoojoozat advanced features and creative design!",
    images: [
      "img/screenshots/welcome.png",
      "img/screenshots/main.png",
      "img/screenshots/reservations.png",
      "img/screenshots/reservationdetails.png",
      "img/screenshots/tables.png",
      "img/screenshots/notifications.png",
      "img/screenshots/commentcard.png",
    ],
  },
  downloadApp: {
    title: "Start managing your business, more faster",
    description:
      "Power yourself with an advanced reservation, waitlist, and seating management platform - fully integrated with your key systems to better service your guests and increase footfall.",
    backgroundImage: "img/screenshots/loading.png",
    appStore: {
      title: "App Store",
      url: "https://apps.apple.com/us/app/hoojoozat-business/id",
    },
    playStore: {
      title: "Google Play",
      url: "https://play.google.com/store/apps/details?id=com.hoojoozatbusiness",
    },
  },
  contact: {
    title: "Contact with us",
    subtitle: "Reach us quickly",
    description:
      "It's very easy to get in touch with us. Just use the contact form or pay us a visit for a coffee at the office. Dynamically innovate competitive technology after an expanded array of leadership.",
    office: "Head Office",
    address: "Arz Centre, 10th Floor, Jal el Dib hight way",
    phone: "+961 3 684 619",
    email: "info@hoojoozatgroup.com",
  },
  team: {
    title: "Our team members",
    members: [
      {
        name: "Maroun Karam",
        designation: "Co Founder, CEO",
        image: "img/team/maroun-karam.png",
        facebook: "",
        twitter: "",
        linkedin: "https://linkedin.com/in/maroun-karam-12b725a0/",
      },
      {
        name: "Naji Chemaly",
        designation: "Co Founder, CTO",
        image: "img/team/naji-chemaly.png",
        facebook: "",
        twitter: "",
        linkedin: "https://linkedin.com/in/najielchemaly",
      },
      {
        name: "Juliano Majally",
        designation: "Sr. Full Stack Developer",
        image: "img/team/juliano-majally.png",
        facebook: "",
        twitter: "",
        linkedin: "https://linkedin.com/in/julianomajally/",
      },
    ],
  },
  footer: {
    socials: {
      facebook: "https://facebook.com/hoojoozatgroup",
      instagram: "https://instagram.com/hoojoozatgroup",
      linkedin: "https://linkedin.com/company/hoojoozatgroup",
    },
    otherLinks: {
      title: "Other Links",
      items: [
        {
          title: "About Us",
          url: "#about",
          pageScroll: true,
        },
        {
          title: "Contact Us",
          url: "#contact",
          pageScroll: true,
        },
        {
          title: "Privacy Policy",
          url: "/privacy",
        },
        {
          title: "Terms and Conditions",
          url: "/terms",
        },
      ],
    },
    support: {
      title: "Support",
      address_1: "Arz Centre, 10th Floor",
      address_2: "Jal el Dib hight way",
      phone: "+961 3 684 619",
      email: "info@hoojoozatgroup.com",
      website: {
        title: "hoojoozatgroup.com",
        url: "https://www.hoojoozatgroup.com",
      },
    },
    location: {
      title: "Location",
      address:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1655.6881495010762!2d35.57350681744386!3d33.90571350000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f3da976cc6b25%3A0xb1f44cd892d298ca!2sCentre%20El%20Arz!5e0!3m2!1sen!2skw!4v1664435295129!5m2!1sen!2skw",
    },
    copyrights: {
      title: "Copyrights © 2022. All rights reserved by ",
      company: "Hoojoozat Group",
    },
  },
};
