import React from "react";
import { connect } from "react-redux";
import { getQuote } from "../../actions/index";
import data from "../../data";
import APIGetQuote from "../../services/APIGetQuote";

class HeroSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hero: {
        video: {
          link: "",
        },
      },
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      isAgreed: false,
      disableBtn: false,
      btnText: "Send",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  validateInput = () => {
    const { name, email, phone, company, message } = this.state;
    return (
      name !== "" &&
      email !== "" &&
      phone !== "" &&
      company !== "" &&
      message !== ""
    );
  };

  changeBtnText = (btnText) => {
    this.setState({ btnText });
  };

  handleFormValueChange(inputName, event) {
    let stateValue = {};
    stateValue[inputName] =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    this.setState(stateValue);
  }

  async handleSubmit(event) {
    try {
      event.preventDefault();

      if (!this.state.isAgreed) {
        alert("You must agree to the terms & conditions before proceeding");
        return;
      }

      // disable the button
      this.setState({ disableBtn: true });

      // // get action
      const quoteAction = getQuote(this.state);

      // // Dispatch the contact from data
      this.props.dispatch(quoteAction);

      const { name, email, phone, company, message } = this.state;
      const result = await APIGetQuote({
        name,
        email,
        phone,
        company,
        message,
      });

      this.resetQuoteState();

      if (result.status === 200) {
        const { message } = result.data;
        if (message) {
          alert(message);
        }
      }
    } catch (error) {
      this.resetQuoteState();
    }
  }

  resetQuoteState = () => {
    // enable the button
    this.setState({ disableBtn: false });

    // change to button name
    this.changeBtnText("Send");

    // get action again to update state
    const quoteAction = getQuote(this.state);

    // Dispatch the contact from data
    this.props.dispatch(quoteAction);

    // clear form data
    this.setState({
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      isAgreed: false,
    });
  };

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      hero: data.hero,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="hero-section background-img pt-100"
          style={{
            backgroundImage: "url('img/hero-bg-4.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6 col-lg-6">
                <div className="hero-content-left position-relative mt-lg-0 mt-md-0 mt-5 text-white">
                  <h1 className="text-white">
                    <span>{this.state.hero.title}</span>
                    {this.state.hero.subtitle}
                  </h1>
                  <p className="lead">{this.state.hero.description}</p>
                  {!this.props.betaRelease && (
                    <div className="video-promo-content mt-4 d-flex align-items-center">
                      <a
                        href={this.state.hero.video.link}
                        className="popup-youtube video-play-icon"
                      >
                        <span className="ti-control-play"></span>{" "}
                      </a>
                      <span className="ml-4">{this.state.hero.watchVideo}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-5 col-lg-5">
                <div className="sign-up-form-wrap position-relative rounded p-5 gray-light-bg mt-5">
                  <div className="sign-up-form-header text-center mb-4">
                    <h4 className="mb-0">{this.state.hero.getQuoteTitle}</h4>
                    <p>{this.state.hero.getQuoteDescription}</p>
                  </div>
                  <form
                    method="post"
                    className="sign-up-form"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="form-group input-group">
                      <input
                        value={this.state.name}
                        onChange={(e) => this.handleFormValueChange("name", e)}
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="Enter your name"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <input
                        value={this.state.email}
                        onChange={(e) => this.handleFormValueChange("email", e)}
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter your email"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <input
                        value={this.state.phone}
                        onChange={(e) => this.handleFormValueChange("phone", e)}
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder="Enter your phone"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <input
                        value={this.state.company}
                        onChange={(e) =>
                          this.handleFormValueChange("company", e)
                        }
                        type="text"
                        name="company"
                        className="form-control"
                        placeholder="Enter your company name"
                        required
                      />
                    </div>
                    <div className="form-group input-group">
                      <textarea
                        onChange={(e) =>
                          this.handleFormValueChange("message", e)
                        }
                        value={this.state.message}
                        name="message"
                        id="msg"
                        className="form-control"
                        placeholder="Write your message"
                        cols="30"
                        rows="4"
                        required
                      ></textarea>
                    </div>
                    <div className="form-group">
                      <input
                        onClick={() => {
                          if (this.validateInput() && this.state.isAgreed) {
                            this.changeBtnText("Sending...");
                          }
                        }}
                        disabled={this.state.disableBtn}
                        type="submit"
                        name="submit"
                        id="submit"
                        className="btn solid-btn btn-block"
                        value={this.state.btnText}
                      />
                    </div>
                    <div className="form-check d-flex align-items-center text-center">
                      <input
                        checked={this.state.isAgreed}
                        onChange={(e) =>
                          this.handleFormValueChange("isAgreed", e)
                        }
                        type="checkbox"
                        name="isAgreed"
                        className="form-check-input mt-0 mr-3"
                        id="ckbAgree"
                      />
                      <label className="form-check-label" htmlFor="ckbAgree">
                        {this.state.hero.agreeToTerms}{" "}
                        <a href="/terms" target={"_blank"}>
                          {this.state.hero.termsAndConditions}
                        </a>
                      </label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="section-shape position-absolute">
            <img src="img/wave-shap.svg" alt="shape" />
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect((state) => ({
  state,
}))(HeroSection);
