import React from "react";
import data from "../../data";

export default class Screenshots extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screenshots: {
        images: [],
      },
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      screenshots: data.screenshots,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="screenshots"
          className="screenshots-section ptb-100 gray-light-bg"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center">
                  <h2>
                    {this.state.screenshots.title} <br />{" "}
                    <span>{this.state.screenshots.subtitle}</span>
                  </h2>
                  <p className="lead">{this.state.screenshots.description}</p>
                </div>
              </div>
            </div>
            <div className="screen-slider-content mt-5">
              <div className="screenshot-frame"></div>
              <div className="screen-carousel owl-carousel owl-theme dot-indicator">
                {this.state.screenshots.images.map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={item}
                      className="img-fluid"
                      alt="Awsome Screenshot"
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
