import React, { useEffect, useState } from "react";
import data from "../../data";

export default function Footer(props) {
  const [state, setState] = useState({
    footer: {
      socials: {},
      otherLinks: {
        items: [],
      },
      support: {
        website: {},
      },
      copyrights: {},
      location: {},
    },
  });

  useEffect(() => {
    setState({ footer: data.footer });
  }, []);

  return (
    <React.Fragment>
      <footer className="footer-section">
        <div
          className={
            "footer-top background-img-2 " +
            (props.noSubscription ? "py-5" : "pt-150 pb-5")
          }
          style={{
            backgroundImage: "url('img/footer-bg.png')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-3 mb-3 mb-lg-0">
                <div className="footer-nav-wrap text-white footer-socials">
                  <img
                    src="img/hoojoozat-logo.png"
                    alt="footer logo"
                    width={"120"}
                    className="img-fluid mb-3"
                  />
                  <p></p>
                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a
                          href={state.footer.socials.facebook}
                          target="_blank"
                          title="Facebook"
                        >
                          <span className="ti-facebook"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={state.footer.socials.instagram}
                          target="_blank"
                          title="Instagram"
                        >
                          <span className="ti-instagram"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={state.footer.socials.linkedin}
                          target="_blank"
                          title="LinkedIn"
                        >
                          <span className="ti-linkedin"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <h5 className="mb-3 text-white">
                    {state.footer.otherLinks.title}
                  </h5>
                  <ul className="list-unstyled">
                    {state.footer.otherLinks.items.map((item, index) => {
                      return (
                        <li key={index} className="mb-2">
                          <a
                            className={item.pageScroll ? "page-scroll" : null}
                            href={item.url}
                            target={item.pageScroll ? "_self" : "_blank"}
                          >
                            {item.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 ml-auto mb-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <h5 className="mb-3 text-white">
                    {state.footer.support.title}
                  </h5>
                  <ul className="list-unstyled support-list">
                    <li className="mb-2 d-flex align-items-center">
                      <span className="ti-location-pin mr-2"></span>
                      {state.footer.support.address_1}
                      <br />
                      {state.footer.support.address_2}
                    </li>
                    <li className="mb-2 d-flex align-items-center">
                      <span className="ti-mobile mr-2"></span>{" "}
                      <a href={`tel:${state.footer.support.phone}`}>
                        {" "}
                        {state.footer.support.phone}
                      </a>
                    </li>
                    <li className="mb-2 d-flex align-items-center">
                      <span className="ti-email mr-2"></span>
                      <a href={`mailto:${state.footer.support.email}`}>
                        {" "}
                        {state.footer.support.email}
                      </a>
                    </li>
                    <li className="mb-2 d-flex align-items-center">
                      <span className="ti-world mr-2"></span>
                      <a
                        href={state.footer.support.website.url}
                        target={"_blank"}
                      >
                        {" "}
                        {state.footer.support.website.title}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footer-nav-wrap text-white">
                  <h5 className="mb-3 text-white">
                    {state.footer.location.title}
                  </h5>
                  <iframe
                    className="google-map"
                    src={state.footer.location.address}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom gray-light-bg pt-4 pb-4">
          <div className="container">
            <div className="row text-center justify-content-center">
              <div className="col-md-6 col-lg-5">
                <p className="copyright-text pb-0 mb-0">
                  {state.footer.copyrights.title}
                  <a href="/#">{state.footer.copyrights.company}</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
