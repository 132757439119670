import React from "react";
import data from "../../data";

class Feature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: {
        items: [],
      },
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      features: data.features,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section
          id="features"
          className="imageblock-section switchable switchable-content"
        >
          <div className="imageblock-section-img col-lg-5 col-md-4">
            <div
              className="background-image-holder"
              style={{
                backgroundImage: "url('img/mainfeatures.png')",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                opacity: 1,
              }}
            >
              {" "}
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-7">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    {this.state.features.items.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="d-flex align-items-start mb-5-u"
                        >
                          <div className="pr-4">
                            <div
                              className={`icon icon-shape rounded-circle icon-color-${
                                index + 1
                              }`}
                            >
                              <span className={`${item.icon}`}></span>
                            </div>
                          </div>
                          <div className="icon-text">
                            <h5>{item.title}</h5>
                            <p className="mb-0">{item.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Feature;
