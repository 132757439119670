import React, { Component } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSection";
import PromoSection from "../components/PromoSection";
import AboutSection from "../components/AboutUs/";
import FeatureSection from "../components/Features/";
import Screenshots from "../components/Screenshots";
import DownloadSection from "../components/Download";
import TeamMember from "../components/TeamMember";
import ContactSection from "../components/Contact";
import FooterSection from "../components/Footer";

class AppTheme extends Component {
  render() {
    const hideLogin = process.env.REACT_APP_HIDE_LOGIN === "true";

    const betaRelease = process.env.REACT_APP_BETA_RELEASE === "true";

    if (betaRelease) {
      return (
        <React.Fragment>
          <Header hideLogin={hideLogin} />
          <div id="home" className="main">
            <HeroSection betaRelease={betaRelease} />
            <PromoSection />
            <AboutSection />
            <FeatureSection />
            <Screenshots />
            <TeamMember />
            <ContactSection />
          </div>
          <FooterSection />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Header hideLogin={hideLogin} />
        <div id="home" className="main">
          <HeroSection />
          <PromoSection />
          <AboutSection />
          <FeatureSection />
          <Screenshots />
          <DownloadSection />
          <TeamMember />
          <ContactSection />
        </div>
        <FooterSection />
      </React.Fragment>
    );
  }
}

export default AppTheme;
