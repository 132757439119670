import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// importing all the themes
import AppTheme from "../themes/appTheme";
import Login from "../themes/login";
import SignUp from "../themes/signup";
import ResetPassword from "../themes/resetPassword";
import ChangePassword from "../themes/changePassword";
import Faq from "../themes/faq";
import NotFound from "../themes/404";
import ComingSoon from "../themes/comingSoon";
import Terms from "../themes/terms";
import PrivacyPolicy from "../themes/privacy";

export default class Routes extends React.PureComponent {
  render() {
    const underConstruction =
      process.env.REACT_APP_UNDER_CONSTRUCTION === "true";

    if (underConstruction) {
      return (
        <React.Fragment>
          <Router>
            <Switch>
              <Route exact path="/" component={ComingSoon} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="*" component={NotFound} />
            </Switch>
          </Router>
        </React.Fragment>
      );
    }

    const betaRelease = process.env.REACT_APP_BETA_RELEASE === "true";

    if (betaRelease) {
      return (
        <React.Fragment>
          <Router>
            <Switch>
              <Route exact path="/" component={AppTheme} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="*" component={NotFound} />
            </Switch>
          </Router>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={AppTheme} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/resetPassword" component={ResetPassword} />
            <Route exact path="/changePassword" component={ChangePassword} />
            <Route exact path="/comingSoon" component={ComingSoon} />
            <Route exact path="/faq" component={Faq} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="*" component={NotFound} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
