import React, { useEffect, useState } from "react";

import Header from "../components/Header/header";
import HeroSection from "../components/HeroSection/HeroSectionFaq";
import FooterSection from "../components/Footer";
import Terms from "../components/Terms";

export default function TermsOfUse() {
  const [width, setWidth] = useState(window.innerWidth);

  const hideLogin = process.env.REACT_APP_HIDE_LOGIN === "true";

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [setWidth]);

  const isMobile = width <= 768;

  const MobileContent = () => (
    <React.Fragment>
      <Terms />
    </React.Fragment>
  );

  const WebContent = () => (
    <React.Fragment>
      <Header navigateToRoot hideLogin={hideLogin} />
      <div className="main">
        <HeroSection title={"Terms of Use"} subtite={"Terms"} />
        <Terms />
      </div>
      <FooterSection noSubscription={true} />
    </React.Fragment>
  );

  const underConstruction = process.env.REACT_APP_UNDER_CONSTRUCTION === "true";
  return underConstruction || isMobile ? <MobileContent /> : <WebContent />;
}
