import React from "react";
import data from "../../data";

class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      about: {
        items: [],
      },
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      about: data.aboutUs,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section id="about" className="about-us ptb-100 gray-light-bg">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="about-content-right">
                  <img
                    src="img/delivery-app.svg"
                    width="500"
                    alt="about us"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <span className="badge badge-primary badge-pill">
                  {this.state.about.subtitle}
                </span>
                <h2 className="mt-4">{this.state.about.title}</h2>
                <p className="mb-4 lh-190">{this.state.about.description}</p>
                <ul className="list-unstyled">
                  {this.state.about.items.map((item, index) => {
                    return (
                      <li key={index} className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <div className="badge badge-circle badge-primary mr-3">
                              <span className="ti-check"></span>
                            </div>
                          </div>
                          <div>
                            <p className="mb-0">{item.title}</p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
