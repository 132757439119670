import React, { useEffect, useState } from "react";
import data from "../../data";

export default function Header(props) {
  const [state, setState] = useState({
    header: {
      items: [],
    },
  });
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setState({
      header: data.header,
    });
  }, []);

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [setWidth]);

  const isMobile = width <= 768;

  return (
    <React.Fragment>
      <header className="header">
        <nav
          className={
            "navbar navbar-expand-lg fixed-top " +
            (props.bgColor && props.bgColor === "white"
              ? "custom-nav white-bg"
              : "bg-transparent")
          }
        >
          <div className="container">
            <a className="navbar-brand" href="/">
              <img
                src={"img/hoojoozat-logo.png"}
                width={isMobile ? "95" : "120"}
                alt="logo"
                className="img-fluid"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>

            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                {state.header.items.map((item, index) => {
                  return (
                    <li key={index} className="nav-item">
                      <a
                        className="nav-link page-scroll"
                        href={`${props.navigateToRoot ? "/" : ""}${item.link}`}
                      >
                        {item.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
              {!props.hideLogin && (
                <span
                  className={`button btn solid-btn-header ${
                    isMobile && "solid-btn-header-mobile"
                  }`}
                  id="signin"
                >
                  <a className="a-btn-header" href="/login">
                    {state.header.login}
                  </a>
                </span>
              )}
            </div>
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
}
