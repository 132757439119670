import { API } from "../index";

export default async function APIContactUs(body) {
  try {
    const axios = await API();
    return await axios.post("/users/web/contact", body);
  } catch (error) {
    throw error;
  }
}
