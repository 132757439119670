import React from "react";
import data from "../../data";

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadApp: {
        playStore: {
          title: "",
          url: "",
        },
        appStore: {
          title: "",
          url: "",
        },
      },
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      downloadApp: data.downloadApp,
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="download-section pt-100 background-img">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-7">
                <div className="download-content text-white pb-100">
                  <h2 className="text-white">{this.state.downloadApp.title}</h2>
                  <p className="lead">{this.state.downloadApp.description}</p>

                  <div className="download-btn">
                    <a
                      href={this.state.downloadApp.playStore.url}
                      target={"_blank"}
                      className="btn google-play-btn mr-3"
                    >
                      <span className="ti-android"></span>{" "}
                      {this.state.downloadApp.playStore.title}
                    </a>
                    <a
                      href={this.state.downloadApp.appStore.url}
                      target={"_blank"}
                      className="btn app-store-btn"
                    >
                      <span className="ti-apple"></span>{" "}
                      {this.state.downloadApp.appStore.title}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="download-img d-flex align-bottom">
                  <img
                    src={this.state.downloadApp.backgroundImage}
                    alt="download"
                    className="img-fluid img-store"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Download;
